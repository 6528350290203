<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Pekerjaan </strong><small>Detail Data</small>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="2">
                <div class="">
                  <label> Tahun Anggaran</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.tahun_anggaran }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Nama Kegiatan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nama }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="2">
                <div class="">
                  <label> Sub Kegiatan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.sub_kegiatan }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Nama Pekerjaan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nama_pekerjaan }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="2">
                <div class="">
                  <label> Nilai Kontrak</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nilai_kontrak }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Nilai Pagu</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nilai }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="2">
                <div class="">
                  <label> Nama Kontraktor</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nama_kontraktor }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Konsultan Supervisi</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nama }}</label>
                </div>
              </CCol>
              </CRow>
              <CRow>              
              <CCol sm="2">
                <div class="">
                  <label> Lokasi Kegiatan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.lokasi }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Tanggal Kontrak</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.tgl_kontrak }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>              
              <CCol sm="2">
                <div class="">
                  <label> Panjang Penanganan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.panjang }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Lebar</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.lebar }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>              
              <CCol sm="2">
                <div class="">
                  <label> Jenis Konstruksi</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.jenis_konstruksi }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Nomor Kontrak</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.nomor_kontrak }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>              
              <CCol sm="2">
                <div class="">
                  <label> Waktu Pelaksanaan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.tgl_mulai }} s/d {{ detail.tgl_selesai }}</label>
                </div>
              </CCol>
              <CCol sm="2">
                <div class="">
                  <label> Jmlh Hari Pelaksanaan</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.jumlah_hari_pelaksanaan }}</label>
                </div>
              </CCol>
            </CRow>
            <CRow>              
              <CCol sm="2">
                <div class="">
                  <label> PPTK</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.pptk }} </label>
                </div>
              </CCol>             
              <CCol sm="2">
                <div class="">
                  <label> Pengawas 1</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.pengawas1 }} </label>
                </div>
              </CCol>
            </CRow>
            <CRow> 
              <CCol sm="2">
                <div class="">
                  <label> Pengawas 2</label>
                </div>
              </CCol>
              <CCol sm="4">
                <div class="">
                  <label> : {{ detail.pengawas2 }}</label>
                </div>
              </CCol>
            </CRow>
            <hr />
            <h5>Input Progress</h5>

            <CForm @submit.prevent="inputProgress">
              <CRow>
                <CCol sm="6">
                  <div class="form-group">
                    <label> Laporan Progress </label>
                    <br />
                    <!-- <CInputFile
                      label="Laporan Progress"
                      :placeholder="laporan"
                      @change="onFileChange2($event)"
                      custom
                    /> -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                      :placeholder="laporan" aria-describedby="inputGroupFileAddon01">
                      <label class="custom-file-label" for="inputGroupFile01">{{laporan}}</label>
                    </div>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label> Foto Progress </label>
                    <br />
                    
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                      :placeholder="foto" aria-describedby="inputGroupFileAddon01">
                      <label class="custom-file-label" for="inputGroupFile01">{{foto}}</label>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <div class="form-group">
                  <label> Rencana Progress Fisik</label>
                  <select v-model="rencana_progress_fisik" class="form-control">
                    <option value="" disabled selected>Pilih  Persentase</option>
                    <option  v-for="dt in options" v-bind:value="dt" v-bind:key="dt" >{{ dt }}</option>
                  </select>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                  <label> Realisasi Progress Fisik</label>
                  <select v-model="realisasi_progress_fisik" class="form-control">
                    <option value="" disabled selected>Pilih  Persentase</option>
                    <option  v-for="dt in options" v-bind:value="dt" v-bind:key="dt" >{{ dt }}</option>
                  </select>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="tgl_input"
                    label="Tgl Input"
                    readonly
                    placeholder="01/01/2021"
                  />
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                  <label> Minggu ke-n</label>
                  <select v-model="mingguke" class="form-control">
                    <option value="" disabled selected>Pilih  Minggu Ke-</option>
                    <option  v-for="rsm in minggu" v-bind:value="rsm" v-bind:key="rsm" >{{ rsm }}</option>
                  </select>
                  </div>
                </CCol>
              </CRow>
              <div class="form-actions">
                <CButton
                  type="submit"
                  color="primary"
                  style="margin-right: 15px"
                  >Simpan</CButton
                >
                <CButton color="secondary" to="/pekerjaan">Batal</CButton>
              </div>
            </CForm>
            <hr>
            <table class="table table-bordered" id="datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Foto</th>
                  <th>Laporan</th>
                  <th>Rencana (%)</th>
                  <th>Realisasi (%)</th>
                  <th>Tgl Input</th>
                  <th>Unduh</th>
                  <th>Hapus</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="(item, index) in products" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.nama}}</td>
              <td>{{item.sub_kegiatan}}</td>
              <td>{{item.nama_pekerjaan}}</td>
              <td>{{ item.nilai_kontrak | rupiah }}</td>
            </tr>
             -->
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal title="Error Input" color="danger" :show.sync="myError" size="sm">
      <b>Error</b>! Data Progress pekerjaan gagal diinput.
    </CModal>

    <CModal title="Sukses Input" color="sucess" :show.sync="mySukses" size="sm">
      <b>Sukses</b>! Data Progress pekerjaan berhasil diinput.
    </CModal>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import * as Modals from "../notifications/Modals.vue";
import $ from "jquery";
// import { CurrencyInput } from 'vue-currency-input'
let tgl = new Date().getDate();
if(tgl<10){
  tgl = "0"+tgl;
}

let bln = new Date().getMonth();
bln = +bln + 1;
if(bln<10){
  bln = "0"+bln;
}

function hapusProgress(id) {
   this.Forms.hapusData(id);    
}

export default {
  // components: {CurrencyInput },
  name: "Forms",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => new Date().getFullYear() - index
      );
    },
  },
  data() {
    return {
      tgl_input : new Date().getFullYear()+"-"+bln +"-"+tgl,
      mySukses: false,
      myError: false,
      detail: "",
      laporan: "Pilih File Laporan Progress",
      foto: "Pilih File Foto Progress",
      selected: [], // Must be an array reference!
      laporan_progress : "",
      foto_progress : "",
      show: true,
      horizontal: { label: "col-3", input: "col-9" },
      options: ["0%","10%","15%","20%", "25%","30%","35%","40%", "45%","50%","55%","60%", "65%", "70%", "75%","80%", "85%","90%", "95%", "100%"],
      minggu: ["1","2","3","4", "5","6","7","8", "9","10","11","12", "13", "14", "15","16", "17","18", "19", "20", "21", "22", "23", "24"],
      jenis: ["Penyelenggaran Jalan Kabupaten/Kota"],
      subjenis: [
        "Pembangunan dan Peningkatan Jalan",
        "Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan",
        "Pembangunan Jembatan / Box Culvert",
      ],
      tahuns: ["2021", "2020"],
      selectOptions: [
        "Option 1",
        "Option 2",
        "Option 3",
        {
          value: "some value",
          label: "Selected option",
        },
      ],
      selectedOption: "some value",

      formCollapsed: true,
      checkboxNames: [
        "Checkboxes",
        "Inline Checkboxes",
        "Checkboxes - custom",
        "Inline Checkboxes - custom",
      ],
      radioNames: [
        "Radios",
        "Inline Radios",
        "Radios - custom",
        "Inline Radios - custom",
      ],
    };
  },
  mounted() {
    this.showPekerjaan();
    this.loadData();
  },
  methods: {
    onFileChange(event) {
      var fileData = event.target.files[0];
      this.laporan = fileData.name;
      this.laporan_progress = fileData;
      // alert(fileData.name);
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto = fileData.name;
      this.foto_progress = fileData;
      // alert(fileData.name);
    },
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputProgress: function (event) {
      // Simple POST request with a JSON body using axios
      // const progress = {
      //   id_rka: this.$route.params.id,
      //   laporan_progress: this.this.laporan_progress,
      //   foto_progress: this.foto_progress,
      //   realisasi_progress_fisik: this.realisasi_progress_fisik,
      //   rencana_progress_fisik: this.rencana_progress_fisik,
      //   dtime: this.tgl_input,
      // };
      const formData = new FormData()
      formData.append('laporan_progress', this.laporan_progress, this.laporan_progress.name)
      formData.append('foto_progress', this.foto_progress, this.foto_progress.name)
      formData.append('id_rka', this.$route.params.id)
      formData.append('foto', this.foto_progress.name)
      formData.append('laporan', this.laporan_progress.name)
      formData.append('realisasi_progress_fisik', this.realisasi_progress_fisik)
      formData.append('rencana_progress_fisik', this.rencana_progress_fisik)
      // alert(JSON.stringify(progress));
      axios
        .post(
          "https://sippd.probowsolution.com/api/upload_progress.php?key=AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
          formData
        )
        .then((response) => {
          // console.log(response);
          // alert(response.data + JSON.stringify(formData));
          if (response.data == "sukses") {
            this.mySukses = true;
            this.loadData();
          } else {
            this.myError = true;
          }
        });
    },
    hapusData: function (id) {
      alert(id);
    },
    showPekerjaan: function (event) {
      // Simple POST request with a JSON body using axios
      const pekerjaan = {
        tahun_anggaran: this.tahun_anggaran,
        nama_kegiatan: this.nama_kegiatan,
        sub_kegiatan: this.sub_kegiatan,
        nama_pekerjaan: this.nama_pekerjaan,
        lokasi: this.lokasi,
        kontraktor: this.kontraktor,
        nilai_pagu: this.nilai_pagu,
        nilai_kontrak: this.nilai_kontrak,
      };
      // alert(JSON.stringify(pekerjaan));
      axios
        .get(
          "https://sippd.probowsolution.com/public/editpekerjaan/" +
            this.$route.params.id +
            "?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
        )
        .then((response) => {
          // this.tahun_anggaran = response.data.tahun_anggaran;
          // this.nama_pekerjaan = response.data.nama_pekerjaan;
          // this.nama_kegiatan = response.data.nama_kegiatan;
          // this.sub_kegiatan = response.data.sub_kegiatan;
          // this.lokasi = response.data.lokasi;
          // this.kontraktor = response.data.kontraktor;
          // this.nilai_pagu = response.data.nilai_pagu;
          // this.nilai_kontrak = response.data.nilai_kontrak;
          this.detail = response.data;
        });
    },    
    loadData: function () {
      let id_rka =  this.$route.params.id;
      console.log("https://sippd.probowsolution.com/api/ajax_detail_pekerjaan.php?action=table_data&k=" +
              id_rka);
      // alert(username);
        // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/public/pekerjaan/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
        // this.products = response.data;
        $("#datatable").DataTable({
          processing: true,
          serverSide: true,
          pageLength: 50,
          ajax: {
            url:
              "https://sippd.probowsolution.com/api/ajax_detail_pekerjaan.php?action=table_data&id_rka=" +
              id_rka,
            dataType: "json",
            type: "POST",
          },
          columns: [
            { data: "no" },
            { data: "foto_progress" },
            { data: "laporan_progress" },
            { data: "rencana_progress_fisik" },
            { data: "realisasi_progress_fisik" },
            { data: "tgl_input" },
            // { data: "edit" },
            { data: "unduh" },
            { data: "hapus" },
          ],
        });

        // })
      
    },
  },
};
</script>
